.projects {
    padding: 2rem;
    text-align: center;
    background: #000;
    color: #fff;
    margin-bottom: 5rem;
  }
  
  .projects h2 {
    font-size: 30px;
    margin-bottom: 5rem;
    color: #fff;
    letter-spacing: 0.25em;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    justify-content: center;
  }
  
  .project-card {
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
    background: #000;
    position: relative;
  }
  
  .project-card a {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  
  .project-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .project-card .project-description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .project-card:hover .project-description {
    display: flex;
  }
  
  .project-title {
    padding: 1rem;
    font-size: 16px; /* Adjusted font size */
    color: #fff;
    background: #000;
    text-align: center;
    letter-spacing: 0.25em;
  }
  