/* src/globalStyles.css */
@import url('https://fonts.googleapis.com/css2?family=Cousine:wght@400;700&display=swap');

body {
  font-family: 'Cousine', monospace;
letter-spacing: 0.25em;
  color: white;
  background-color: #000;
}

h1 {
  font-size: 34px;
  letter-spacing: 0.25em;
}

h2 {
  font-size: 30px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

a {
  font-size: 24px;
}

footer p {
  font-size: 14px;
}

.cousine-regular {
  font-family: "Cousine", monospace;
  font-weight: 400;
  font-style: normal;
}

.cousine-bold {
  font-family: "Cousine", monospace;
  font-weight: 700;
  font-style: normal;
}
